.contact-screen {
   padding-top: 115px;
   background: var(--modal_bg);
   overflow: hidden;
   position: relative;

   &:after {
      content: '';
      position: absolute;
      top: -20px;
      right: -410px;
      width: 706.992px;
      height: 172.492px;
      transform: rotate(-32.841deg);
      border-radius: 706.992px;
      opacity: 0.3;
      background: radial-gradient(50% 50% at 50% 50%, #5DE9E9 0%, rgba(93, 233, 233, 0.30) 52.4%, rgba(93, 233, 233, 0.11) 77.4%, rgba(93, 233, 233, 0.00) 100%);
   }

   .contact {
      padding: 50px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid var(--action);
      gap: 20px;

      &__item {
         display: flex;
         align-items: center;
         gap: 8px;
         cursor: pointer;

         .icon {
            width: 43px;
            height: 43px;
            flex: 0 0 43px;
            transition: all .8s ease;
         }

         span {
            font-family: var(--text_font);
            font-size: 18px;
            font-weight: 300;
            line-height: 125%;
            color: var(--light);
         }

         &.address {
            max-width: 340px;
            display: block;

            img {
               margin-right: 10px;
               display: block;
               float: left;
            }

            span {
               position: relative;
               top: 3px;
            }
         }

         &:hover {
            span {
               color: var(--action_secondary);
               // left: 2px;
               
            }

            .icon {
               animation: spin 1.2s ease forwards;
            }
         }
      }
   }

   .map {
      width: 100%;
   }

   @media all and (max-width: 1000px) {
      .contact {
         flex-wrap: wrap;
         // justify-content: center;
         gap: 20px;

         &__item {
            margin-bottom: 30px;
            max-width: 47%;
            flex: 0 0 47%;
         }
      }
   }

   @media all and (max-width: 730px) {
      .contact {
         gap: 30px;

         &__item {
            margin-bottom: 0;
            max-width: 100%;
            flex: 0 0 100%;

            span {
               background: -webkit-gradient(linear, left top, left bottom, from(var(--text)));
               background-repeat: no-repeat;
               background-position: left 0% bottom;
               background-size: 100% 1px;
            }

            &:hover {
               span {
                  background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
                  background-repeat: no-repeat;
                  background-position: left 0% bottom;
                  background-size: 100% 1px;
               }
            }
         }
      }
   }

   @media all and (max-width: 370px) {
      .contact {
         &__item span {
            font-size: 16px;
         }
      }
   }
}