button {
   margin: 0;
   padding: 0;
   border: none;
   background: transparent;
}

.ui-btn {
   padding: 17px 55px;
   font-family: var(--title_font);
   font-size: 17px;
   font-weight: 500;
   line-height: 100%;
   color: var(--light);
   border-radius: 5px;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   gap: 10px;
   overflow: hidden;
   position: relative;
   transition: all .4s ease;

   span, i, img {
      position: relative;
      line-height: 100%;
      z-index: 1;
      transition: all .2s ease;
   }

   &:after, &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 5px;
   }

   &:before {
      width: calc(100% - 4px);
      height: calc(100% - 4px);
   }

   &:after {
      z-index: 0;
      animation: buttonHoverAnimationRev .4s ease-out forwards;
   }

   &:before {
      background: 0 0;
      border: 2px solid transparent;
      z-index: 1;
   }

   // Цветная
   &.colorful {
      white-space: nowrap;
      position: relative;
      text-align: center;

      i {
         margin-top: 1px;
         font-size: 10px;
         color: var(--light);
      }

      &:after {
         background: var(--btnG);
      }

      &:before {
         border-color: var(--action);
      }

      &:hover {
         span, i {
            color: var(--light);
         }

         &:after {
            animation: buttonHoverAnimation .4s ease-out forwards;
         }
      }
   }

   // Белый бордер
   &.border_white {
      &:after {
         background: var(--light);
         transition: all .4s ease;
         animation: buttonHoverAnimation .4s ease-out forwards;
      }

      &:before {
         border-color: var(--light);
         transition: all .4s ease;
      }

      i {
         margin-top: 1px;
         font-size: 10px;
      }

      &:hover {
         &:after {
            background: var(--action);
            animation: buttonHoverAnimationRev .4s ease-out forwards;
         }

         &:before {
            border-color: var(--action);
         }
      }

      &.back {
         i {
            transform: rotate(-180deg);
         }
      }
   }

   &.btn_long {
      white-space: wrap;
      text-align: center;
   }

   &.btn_sm {
      padding-top: 11px;
      padding-bottom: 11px;
      font-size: 13px;

      &.colorful {
         i {
            font-size: 13px;
         }
      }
   }

   &.btn_tab {
      padding: 16px 35px;
      height: 43px;
      font-family: var(--text_font);
      font-size: 15px;
      font-weight: 400;
      line-height: 100%;
      text-transform: uppercase;
      display: flex;
      flex: 0 0 auto;
      border: none;

      &:after {
         background: var(--action);
         animation: buttonHoverAnimation .4s ease-out forwards;
      }

      &:before {
         border: 1px solid var(--action);
      }
   
      span, i {
         color: var(--text);
         transition: all .4s ease;
      }

      &:hover, &.active {
         &:after {
            animation: buttonHoverAnimationRev .4s ease-out forwards;
         }

         span, i {
            color: var(--light);
         }
      }
   }

   @media all and (max-width: 400px) {
      padding: 19px 20px;
      max-width: 100%;
      width: 100%;

      &.btn_tab {
         width: auto;
      }
   }
}


@keyframes buttonHoverAnimation {
   0% { left: 0; }
   100% { left: 100%; }
}


@keyframes buttonHoverAnimationRev {
   0% { left: -100%; }
   100% { left: 0; }
}
