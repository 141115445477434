$iconSize: 80px;

.start-screen {
   padding-top: 115px;
   background: var(--dark);
   overflow-x: hidden;

   .ui-container {
      position: relative;
   }

   // NOTE start items
   .start-items {
      display: grid;
      grid-template: auto / repeat(4, 1fr);
      gap: 20px;
      position: relative;
      z-index: 1;

      .start-item {
         position: relative;
         perspective: 900px;
         min-height: 200px;
         display: flex;

         .front, .back {
            padding: 25px 30px;
            min-height: 200px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 10px;
            width: 100%;
            backface-visibility: hidden;
            cursor: pointer;
            transition: all 0.8s ease;
   
            .icon {
               position: absolute;
               top: -55px;
               left: 50%;
               margin-left: -45px;
               width: 90px;
               height: 90px;
            }
   
            .text {
               font-family: var(--text_font);
               font-size: 15px;
               font-weight: 400;
               line-height: 140%;
               color: var(--text);
               text-align: center;
            }
         }
   
         .front {
            justify-content: flex-start;
            position: relative;
            @extend %glass;

            .title {
               margin-top: 15px;
               margin-bottom: 15px;
               font-size: 20px;
               font-weight: 600;
               line-height: 130%;
               min-height: 52px;
               color: var(--light);
               display: flex;
               align-items: center;
               justify-content: center;
               text-align: center;
            }
         }
   
         .back {
            background: var(--action);
            transform: rotateY(180deg);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            .icon {
               margin-bottom: 20px;
            }

            .text {
               margin-top: 30px;
               font-weight: 300;
               color: var(--light);
               flex: 1 1 auto;
               max-width: 234px;
            }
   
            a {
               display: flex;
               align-items: center;
               gap: 6px;
               font-size: 13px;
               font-weight: 600;
               color: var(--light);
               white-space: nowrap;
               cursor: pointer;

               span {
                  text-decoration: underline;
                  transition: all .15s ease;
               }
   
               i {
                  margin-bottom: -1px;
                  font-size: 9px;
                  transition: all .15s ease;
               }
   
               &:hover {
                  span { text-decoration: underline; color: var(--dark); }
                  i { transform: translateX(3px); color: var(--dark); }
               }
            }
         }

         &.hover {
            .front { transform: rotateY(-180deg); }
            .back { transform: rotateY(0); }
         }

         @media (hover: none) {
            .front {
               .title {
                  text-decoration: underline;
                  text-decoration-skip-ink: none;
               }
            }
         }

         @media (hover: hover) {
            &:hover {
               .front { transform: rotateY(-180deg); }
               .back { transform: rotateY(0); }
            }
         }
      }
   }


   // NOTE start steps
   .start-steps {
      padding-bottom: 115px;
      position: relative;
      
      &__title {
         margin-bottom: 40px;
         font-size: 24px;
         font-weight: 700;
         line-height: 130%;
         color: var(--light);
         max-width: 670px;
         position: relative;
         z-index: 1;
      }

      &__items {
         display: grid;
         grid-template: auto / 1fr;
         max-width: clamp(450px, 60cqi, 580px);
         position: relative;
         z-index: 1;

         .start-step {
            display: flex;
            align-items: flex-start;
            position: relative;
            gap: 30px;

            &:not(:last-child) {
               &:after {
                  content: '';
                  position: absolute;
                  top: $iconSize;
                  left: calc(#{$iconSize} / 2 - 1px);
                  height: calc(100% - #{$iconSize});
                  border: 1px dashed var(--action);
               }
            }

            .icon {
               width: $iconSize;
               height: $iconSize;
               flex: 0 0 $iconSize;
            }

            .content {
               margin-top: calc(#{$iconSize} / 2 - 15px);

               .title {
                  margin-bottom: 11px;
                  font-size: 22px;
                  font-weight: 600;
                  line-height: 130%;
                  color: var(--light);
               }

               .text {
                  padding-bottom: 25px;
                  font-family: var(--text_font);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 150%;
                  color: var(--text);
               }
            }

            &:nth-child(1) { 
               .icon { animation: rotationPolygon 4s ease infinite forwards; }
            }
            &:nth-child(2) { 
               .icon { animation: rotationPolygon 4s ease .1s infinite forwards; }
            }
            &:nth-child(3) { 
               .icon { animation: rotationPolygon 4s ease .2s infinite forwards; }
            }
         }
      }

      .start-img {
         position: absolute;
         top: 50%;
         transform: translateY(-50%);
         right: 0;
         width: clamp(320px, 55cqi, 960px);
         height: 100%;
         max-width: 100%;
         object-fit: cover;
         object-position: left center;
      }
   }

   @media all and (max-width: 1000px) {
      .start-items {
         grid-template: 1fr 1fr / 1fr 1fr;
         row-gap: 70px;

         .start-item {
            perspective: 1500px;
         }
      }
   }

   @media all and (max-width: 930px) {
      .start-steps {
         &__items {
            .start-step {
               .content {
                  .text {
                     br {
                        display: none;
                     }
                  }
               }
            }
         }
      }
   }

   @media all and (max-width: 530px) {
      .start-items {
         grid-template: auto / 1fr;
         row-gap: 70px;

         .start-item {
            perspective: 1500px;
         }
      }

      .start-steps {
         $iconSize: 75px;

         .start-steps__title {
            font-size: 22px;
         }

         &__items {
            .start-step {
               gap: 15px;

               .icon {
                  width: $iconSize;
                  height: $iconSize;
                  flex: 0 0 $iconSize;
               }

               &:not(:last-child) {
                  &:after {
                     top: $iconSize;
                     left: calc(#{$iconSize} / 2 - 1px);
                     height: calc(100% - #{$iconSize});
                  }
               }

               .content {
                  margin-top: calc(#{$iconSize} / 2 - 13px);

                  .title {
                     font-size: 20px;
                  }
               }
            }
         }
      }
   }

   @media all and (max-width: 350px) {
      .start-steps {
         &__items {
            gap: 50px;

            .start-step {
               flex-direction: column;

               &:after {
                  display: none;
               }

               .content {
                  margin-top: 0;

                  .text {
                     padding-bottom: 0;
                  }
               }
            }
         }
      }
   }

   @media all and (min-width: 2500px) {
      .start-steps {
         .start-img {
            right: -400px;
         }
      }
   }
}