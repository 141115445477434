.review-wrap {
   display: flex;
   height: auto;
   transition: all .3s ease;

   &:hover {
      transform: translateY(-5px);
   }

   .review {
      padding: 40px 30px 25px 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border: 1px solid #000;
      background: linear-gradient(118deg, rgba(255, 255, 255, 0.10) 30.88%, rgba(36, 40, 41, 0.00) 113.27%, rgba(255, 255, 255, 0.00) 113.27%);
      // backdrop-filter: blur(5px);
      border-image-slice: 1;
      border-image-source: radial-gradient(at center, rgba(255, 255, 255, .05), rgba(255, 255, 255, 0));
      border-radius: 10px;
      // min-width: 420px;
      .author {
         margin-bottom: 25px;
   
         &__name {
            margin-bottom: 3px;
            font-size: 18px;
            font-weight: 600;
            line-height: 120%;
            color: var(--light);
         }
   
         time {
            font-size: 13px;
            font-weight: 500;
            line-height: 100%;
            color: var(--slowlest_text);
         }
      }
   
      .text {
         margin-bottom: 36px;
         font-family: var(--text_font);
         font-size: 14px;
         font-weight: 300;
         line-height: 160%;
         color: var(--text);
         flex: 1 0 auto;
         // display: -webkit-box;
         //    -webkit-line-clamp: 7;
         //    -webkit-box-orient: vertical;
         //    overflow: hidden;
      }
   
      .info {
         display: flex;
         align-items: center;
         justify-content: space-between;
         width: 100%;
   
         .rate {
            display: flex;
            align-items: center;
            gap: 11px;
   
            img {
               width: 76px;
               height: auto;
               flex: 0 0 76px;
            }
   
            .num {
               font-family: var(--text_font);
               font-size: 13px;
               font-weight: 400;
               line-height: 100%;
               color: var(--light);
            }
         }
   
         .more {
            display: flex;
            align-items: center;
            gap: 11px;
   
            span {
               font-family: var(--text_font);
               font-size: 12px;
               font-weight: 400;
               line-height: 100%;
               color: var(--slowlest_text);
            }

            i {
               font-size: 11px;
               color: var(--slowlest_text);
               transition: all .2s ease;
            }
            
            &:hover {
               i, span {
                  color: var(--action_secondary);
               }
            }
         }
      }
   }
}
