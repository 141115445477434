@import './numerical-advantages/numerical-advantages';

.about-screen {
   margin-top: -3px;
   padding: 115px 0;
   background: var(--modal_bg);
   overflow-x: hidden;
   position: relative;

   &:after {
      content: '';
      position: absolute;
      top: -500px;
      left: -500px;
      width: 900px;
      height: 900px;
      border-radius: 50%;
      opacity: 0.3;
      background: radial-gradient(50% 50% at 50% 50%, #5DE9E9 0%, rgba(93, 233, 233, 0.09) 82.08%, rgba(93, 233, 233, 0.00) 100%);
   }

   .ui-container {
      position: relative;
      z-index: 1;
   }

   &:before {
      content: '';
      border-radius: 918.575px;
      opacity: 0.3;
      background: radial-gradient(50% 50% at 50% 50%, #5DE9E9 0%, rgba(93, 233, 233, 0.11) 78.44%, rgba(93, 233, 233, 0.00) 100%);
      width: 70cqi;
      height: 70cqi;
      position: absolute;
      left: -30%;
      top: -100%;
   }

   

   .about-content-wrap {
      padding-bottom: 142px;
      position: relative;
   }

   // NOTE Аккордион с текстом
   .about-content {
      display: grid;
      grid-template: auto / minmax(300px, 420px) minmax(400px, 1fr);
      align-items: start;
      gap: 54px;
      position: relative;
      z-index: 1;
   }

   // NOTE Текст рядом с аккордионом
   .about-info {
      &__title {
         margin-bottom: 24px;
         font-size: 26px;
         font-weight: 700;
         line-height: 130%;
         color: var(--light);
      }
      & > .text {
         margin-bottom: 20px;
         font-family: var(--text_font);
         font-size: 15px;
         font-weight: 400;
         line-height: 150%;
         color: var(--text);
         text-wrap: balance;
      }

      &__action {
         margin-top: 40px;
         .title {
            margin-bottom: 10px;
            font-size: 22px;
            font-weight: 700;
            line-height: 130%;
            color: var(--light);
         }

         .text {
            margin-bottom: 40px;
            font-family: var(--text_font);
            font-size: 16px;
            font-weight: 500;
            line-height: 150%;
            color: var(--light);
            max-width: 495px;
         }
      }
   }

   // NOTE Изображение
   .about-content-img {
      position: absolute;
      top: 180px;
      right: -3cqi;
      width: clamp(400px, 50cqi, 950px);
      height: 100%;
      max-height: 710px;
      object-fit: cover;
      object-position: left center;
   }

   // NOTE about location
   .about-location-wrap {
      .location-subtitle {
         margin-bottom: 80px;
         font-size: 18px;
         font-weight: 500;
         line-height: 130%;
         color: var(--text);
         text-align: center;
      }

      .about-location {
         display: grid;
         grid-template: auto / repeat(3, 1fr);
         gap: 20px;

         &__item {
            .img {
               position: relative;
               border-radius: 5px;
               display: flex;
               justify-content: center;
               transition: all .3s ease;
   
               img {
                  max-width: 100%;
                  height: auto;
                  border-radius: 5px;
               }
   
               .icon-wrap {
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: -40px;
                  background: var(--modal_bg);
                  border-radius: 50%;
                  width: 80px;
                  height: 80px;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .icon {
                     display: flex;
                     align-items: center;
                     justify-content: center;
                     width: 60px;
                     height: 60px;
                     background: var(--action);
                     border-radius: 50%;
                     transition: all .8s ease;
                  }
   
                  i {
                     font-size: 30px;
                     color: var(--light);
                     transition: inherit;
                  }
               }
            }  

            .content {
               margin-top: 60px;
               padding: 0 15px;
               display: flex;
               flex-direction: column;
               align-items: center;

               .title {
                  margin-bottom: 10px;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 140%;
                  color: var(--light);
                  text-align: center;
               }

               .text {
                  font-family: var(--text_font);
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 140%;
                  color: var(--text);
                  text-align: center;
               }
            }

            &:hover {
               .img {
                  transform: translateY(-5px);

                  .icon-wrap .icon {
                     transform: rotateY(360deg);
                  }
               }
            }
         }
      }
   }


   

   @media all and (max-width: 800px) {
      .about-content {
         grid-template: auto / 1fr;
      }

      .about-content-wrap {
         .about-content-img {
            right: 0;
            width: 60cqi;
         }
      }

      .about-location-wrap {
         .about-location {
            grid-template: auto / 1fr;
            row-gap: 70px;
            &__item {
               .content {
                  margin-top: 50px;

                  .text {
                     max-width: 450px;
                  }
               }
            }
         }
      }
   }

   @media all and (max-width: 480px) {
      .about-features {
         grid-template: auto / 1fr;
      }
   }

   @media all and (min-width: 1920px) {
      .about-content-wrap {
         .about-content-img {
            width: 50cqi;
            max-height: 100%;
         }
      }
   }
}