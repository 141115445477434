header {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   z-index: 2;
}

.header-top {
   padding: 2px 0 0 0;
   display: flex;
   align-items: center;
   justify-content: space-between;
   gap: 15px;
   background: var(--modal_bg);

   .ui-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      width: 100%;
   }

   .work {
      font-size: 13px;
      font-weight: 400;
      line-height: 169.231%;
      letter-spacing: 0.1px;
      color: var(--text);
      display: flex;
      align-items: center;
      gap: 10px;

      i {
         color: var(--light);
         font-size: 16.5px;
      }

   }

   .header-top__link {
      display: flex;
      align-items: center;
      gap: 9px;
      cursor: pointer;

      span {
         color: var(--text);
         font-size: 13px;
         font-weight: 400;
         line-height: 169.231%;
         position: relative;
      }

      i {
         color: var(--light);
         font-size: 16px;
      }

      &:hover {
         i {
            color: var(--action_secondary);
         }
      }
   }


   // TODO Ссылка в header-top
   &__menu {
      display: flex;
      align-items: flex-end;
      gap: 4px;

      li {
         transition: all .2s ease;
         
         a {
            padding: 0 clamp(30px, 3cqi, 40px);
            font-size: 15px;
            font-weight: 400;
            line-height: 100%;
            color: var(--slow_text);
            display: flex;
            align-items: center;
            gap: 10px;
            height: 31px;
            white-space: nowrap;
            position: relative;
            transition: all .2s ease;

            &:before, &:after {
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               width: 100%;
               height: 100%;
               background: var(--header_tab);
               display: block;
               border-radius: 3px;
               transform: skew(-30deg);
               transition: all .2s ease;
            }

            &:after {
               right: 1px;
               left: auto;
               display: none;
               transform: skew(30deg);
            }

            i {
               font-size: 12px;
               color: inherit;
            }

            i, span {
               position: relative;
               z-index: 1;
               transition: all .2s ease;
            }
         }

         &:hover {
            a {
               color: var(--action_secondary);

               &:before, &:after {
                  background: #3F484B;
               }
   
            }
         }

         &.active {
            a {
               color: var(--action_secondary);
               height: 35px;

               &:before, &:after {
                  background: #3F484B;
                  width: 70%;
               }

               &:before {
                  left: 1px;
               }

               &:after {
                  display: block;
               }
            }

            & ~ li {
               a {
                  &:before {
                     transform: skew(30deg);
                  }
               }
            }
         }
      }
   }



   @media all and (max-width: 980px) {
      .ui-container {
         justify-content: center;
      }

      .header-top__link, .work {
         display: none;
      }
   }

   @media all and (max-width: 670px) {
      .ui-container {
         justify-content: flex-start;
         overflow-x: auto;
         overflow-y: visible;
         scrollbar-width: none;

         &::-webkit-scrollbar {
            background: #000301;
            width: 5px;
            height: 5px;
            display: none;
         }
         
         &::-webkit-scrollbar-thumb {
            background: var(--border);
            border-radius: 50px;
         }
         
         &::-webkit-scrollbar-corner {
            background: transparent;
         }
      }
   }

   @media all and (max-width: 600px) {
      .header-top .header-top__link {
         span {
            text-decoration: underline;
            &:after{
               display: none;
            }
         }
      }
   }
}