:root {
   // Colors
   --light: #fff;
   --dark: #191E24;
   --dark_content_bg: #1A1F20;
   --dark_title_bg: #212729;
   --controls_bg: #252B2D;
   --header_tab: #313233;
   --modal_bg: #121617;
   --overlay: rgba(41, 48, 51, .9);
   --border: #3B434A;
   --text: #AAB0B4;
   --slow_text: #808688;
   --slowlest_text: #6E7676;
   --action_secondary: #5CE7E7;
   --action: #099AAE;
   --footer_bg: #181a1b;
   --th: rgba(59, 67, 70, 0.95);

   // Gradients
   --btnG: linear-gradient(180deg, #0DA1B6 50%, #098EA0 53.12%);
   --titleG: linear-gradient(180deg, #5DE9E9 50.96%, #3ED8D8 52.43%);
   --glassG: linear-gradient(128deg, rgba(255, 255, 255, 0.10) 20.49%, rgba(36, 40, 41, 0.00) 135.43%, rgba(255, 255, 255, 0.00) 135.43%);
   --dark_glassG: linear-gradient(180deg, rgba(54, 64, 64, 0.8) 0%, rgba(0, 0, 0, 0.32) 100%);
   --border_glassG: radial-gradient( 
      farthest-side at 50% 50%,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0)
   );
   --dark_verticalG: linear-gradient(180deg, #070908 0%, rgba(7, 9, 8, 0.19) 56.4%, rgba(7, 9, 8, 0) 100%);
   --headerG: linear-gradient(180deg, rgba(63, 72, 75, .5) 45.62%, rgba(0, 0, 0, .2) 47.71%);


   // Fonts
   --icon_font: iql;
   --title_font: Montserrat, sans-serif;
   --text_font: Roboto, sans-serif;
   --basic_font_size: 17px;
}