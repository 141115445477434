.action-screen {
   margin-bottom: -1px;
   padding: 70px 0;
   background: url('../assets/img/action/action_bg.webp') no-repeat center / cover;
   
   .ui-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
   }

   .action-title {
      color: var(--light);
      font-size: 36px;
      font-weight: 400;
      line-height: 145%;
      text-transform: uppercase;
      max-width: 560px;

      strong {
         font-weight: 800;
      }
   }

   .text {
      font-family: var(--text_font);
      font-size: 20px;
      font-weight: 300;
      line-height: 145%;
      color: var(--light);
      text-transform: none;
   }

   .action-arrow {
      color: var(--light);
      font-size: 14px;
   }

   @media all and (min-width: 601px) {
      .maxw600 {
         display: none;
      }
   }

   @media all and (max-width: 1130px) {
      .ui-container {
         flex-direction: column;
         align-items: center;

         .action-title {
            text-align: center;
         }

         .action-arrow {
            display: none;
         }
      }
   }

   @media all and (max-width: 600px) {
      .action-title {
         font-size: 31px;
         line-height: 130%;
      }

      .minw600 {
         display: none;
      }

      .maxw600 {
         display: inline-flex;
      }
   }
}