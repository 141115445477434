// NOTE Числовые показатели
.numerical-advantages {
   display: grid;
   grid-template: auto / repeat(4, 1fr);
   gap: 30px;
   position: relative;
   z-index: 1;

   &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
         font-size: clamp(55px, 6cqi, 72px);
         font-weight: 400;
         line-height: 100%;
         color: var(--action_secondary);
         width: 100%;
         text-align: center;
         &:after {
            content: '';
            margin: 13px auto 20px auto;
            display: block;
            background: var(--action);
            width: 70%;
            max-width: 220px;
            height: 1px;
         }
      }

      .text {
         font-family: var(--text_font);
         font-size: 16px;
         font-weight: 300;
         line-height: 143.75%;
         text-align: center;
         color: var(--light);
         max-width: 260px;
      }
   }

   @media all and (max-width: 1000px) {
      grid-template: auto / repeat(2, 1fr);
      row-gap: 50px;
      
      &__item {
         .text {
            max-width: 100%;
         }
      }
   }

   @media all and (max-width: 480px) {
      grid-template: auto / 1fr;
   }


}