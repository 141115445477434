.swimming-chat {
   position: fixed;
   right: 15px;
   bottom: 40px;
   width: 50px;
   height: 50px;
   z-index: 1;
   
   .swimming-stock {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--action);
      cursor: pointer;
      width: 50px;
      height: 50px;
      position: relative;
      z-index: 2;
      transition: all .4s ease;

      i {
         font-size: 20px;
         color: var(--light);

         &.i-close {
            font-size: 18px;
         }
      }

      &:hover {
         background: var(--action_secondary);
      }
   }

   .swimming-links a {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--action);
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;
      width: 50px;
      height: 50px;
      transition: all .4s ease;
      z-index: 0;

      i {
         font-size: 20px;
         color: var(--light);
      }

      &:hover {
         background: var(--action_secondary);
      }
   }

   &.active {
      .swimming-stock {
         background: var(--action_secondary);
      }

      .swimming-links a {
         img {
            width: 22px;
         }
         &:nth-child(1) {
            transform: translateY(-60px);
         }

         &:nth-child(2) {
            transform: translateY(-120px);
         }

         &:nth-child(3) {
            transform: translateY(-180px);
         }
      }
   }

   @media all and (max-width: 340px) {
      bottom: 15px;
   }
}