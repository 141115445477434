.section-title {
   margin-bottom: 56px;
   font-family: Montserrat;
   font-size: clamp(26px, 7cqi, 44px);
   font-weight: 700;
   line-height: 110%;
   color: var(--light);
   text-align: center;
}

.about-screen {
   .section-title {
      margin-bottom: 86px;
   }
}

.about-screen {
   .about-location-wrap {
      .section-title {
         margin-bottom: 20px;
         text-align: center;
      }
   }
}

.faq-screen .section-title {
   margin-bottom: 30px;
   text-align: center;
}

.start-screen .section-title {
   margin-bottom: 96px;
   text-align: center;
}


.reviews-screen {
   .section-title {
      margin-bottom: 90px;
   }
}

// @media all and (max-width: 460px) {
//    .section-title {
//       font-size: 8cqi;          
//    }
// }