.faq-screen {
   margin-top: -2px;
   padding: 115px 0;
   background: var(--modal_bg);
   position: relative;
   overflow: hidden;

   &.soft_faq {
      padding-bottom: 0;
   }

   .right-img, .left-img {
      position: absolute;
      top: 0;
      z-index: 0;
   }
   
   .left-img {
      left: 0;
      top: 0;
      width: 32cqi;
      height: 791px;
      object-fit: cover;
      object-position: right center;
   }

   .right-img {
      right: -4cqi;
      width: 32cqi;
      height: 703px;
      top: 0;
      object-fit: cover;
      object-position: left center;
   }

   .ui-container {
      position: relative;
      z-index: 1;
   }

   .faq-subtitle {
      margin: 0 auto 60px auto;
      font-size: 18px;
      font-weight: 500;
      line-height: 140%;
      color: var(--text);
      text-align: center;
      max-width: 405px;
   }

   .faq-tabs {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      

      &::-webkit-scrollbar {
         display: none;
      }

      .btn-tab {
         span {
            margin-top: 2px;
            color: var(--text);
            font-family: var(--text_font);
            font-size: 16px;
            font-weight: 400;
            line-height: 100%;
            text-transform: uppercase;
            display: inline-block;
            transition: all .2s ease;
         }
      }
   }

   .faq-content {
      margin: 0 auto;
      max-width: 770px;
      position: relative;
      z-index: 1;

      
      .faq-more {
         margin: 15px auto 0 auto;
         font-size: 16px;
         color: var(--text);
         opacity: .7;
         text-align: center;
         display: block;
         cursor: pointer;
         transition: all .2s ease;

         &:hover {
            opacity: 1;
            color: var(--light);
         }

         &.active {
            transform: rotate(-180deg);
         }
      }
   }

   @media all and (max-width: 760px) {
      .faq-tabs {
         margin-bottom: 30px;
         padding-bottom: 10px;
         justify-content: flex-start;
         gap: 15px;
         width: 100%;
         overflow-x: auto;
      }
   }
}