.header-sticky {
   padding: 10px 0;
   background: rgba(59, 67, 70, .95);
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   transition: all .2s ease;
   transform: translateY(-100%);

   &.active {
      transform: none;
   }

   .ui-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
   }

   .nav-wrap {
      display: flex;
      align-items: center;
      gap: 68px;

      .logo {
         max-width: 125px;
         transition: all .2s ease;

         img {
            max-width: 100%;
            height: auto;
         }
         &:hover {
            transform: translateX(5px);
         }
      }

      .sticky-nav {
         ul {
            display: flex;
            align-items: center;
            gap: 36px;

            li a {
               color: var(--text);
               font-family: var(--title_font);
               font-size: 16px;
               font-weight: 500;
               line-height: 130%;
               transition: all .2s ease;
               position: relative;

               &:after {
                  content: '';
                  position: absolute;
                  bottom: -3px;
                  left: 0;
                  width: 0;
                  height: 1px;
                  background: var(--action_secondary);
                  transition: inherit;
               }
   
               &:hover {
                  color: var(--action_secondary);
                  
                  &:after {
                     width: 100%;
                  }
               }
            }
         }
      }
   }

   &__contact {
      display: flex;
      align-items: center;
      gap: 15px;

      a {
         display: flex;
         align-items: center;
         transition: all .2s ease;
         position: relative;
         gap: 6px;
         cursor: pointer;

         span, i {
            color: var(--text);
         }

         i {
            font-size: 16px; 
         }

         span {
            font-size: 16px;
            font-weight: 400;
            line-height: 100%;
         }

         &:not(:last-child) {
            padding-right: 15px;
         }

         &:hover {
            i, span {
               color: var(--action_secondary);
            }
         }
      }
   }

   @media all and (max-width: 1270px) {
      .nav-wrap {
         gap: 50px;

         .sticky-nav {
            ul {
               gap: 25px;
   
               li a {
                  font-size: 15px;
               }
            }
         }
      }

      &__contact {
         gap: 12px;

         a {
            span, i {
               font-size: 14px;
            }

            &:not(:last-child) {
               padding-right: 12px;
            }
         }
      }
   }

   @media all and (max-width: 1115px) {
      &__contact {
         a {
            &:last-child {
               display: none;
            }
  
         }
      }
   }

   @media all and (max-width: 835px) {
      .nav-wrap {
         gap: 35px;

         .sticky-nav {
            display: none;
         }
      }
   }

   @media all and (max-width: 600px) {
      .nav-wrap .logo {
         max-width: 130px;
      }
      &__contact {
         a {
            span {
               text-decoration: underline;
               &:after {
                  display: none;
               }

               &:hover {
                  color: var(--light);
               }
            }
         }
      }
   }

   @media all and (max-width: 550px) {
      .nav-wrap {
         width: 100%;
         justify-content: space-between;
      }

      &__contact {
         display: none;
      }
   }
}