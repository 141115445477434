@import 'header-top/header-top';

header {
   width: 100%;

   img {
      max-width: 200px;
      height: auto;
   }
}


.header-bottom {
   padding: 10px 0;
   background: var(--headerG);

   .ui-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 40px;
   }
   
   .logo {
      margin-right: auto;
      max-width: 166px;
      transition: all .2s ease;
      img {
         max-width: 100%;
         height: auto;
      }

      &:hover {
         transform: translateX(5px);
      }
   }

   .header-menu {
      margin-right: 80px;

      ul {
         display: flex;
         align-items: center;
         gap: clamp(15px, 2vw, 30px);;

         a {
            padding: 3px 0;
            font-size: clamp(.8rem, 1.3vw, 1rem);
            font-weight: 500;
            line-height: 130%;
            color: var(--light);
            white-space: nowrap;
            position: relative;

            &:hover {
               color: var(--action_secondary);
            }
         }
      }
   }

   .header-action {
      display: flex;
      align-items: center;
      gap: 16px;

      .header-bottom__link {
         display: flex;
         align-items: center;
         gap: 8px;
         position: relative;
         cursor: pointer;

         i {
            font-size: 15px;
            color: var(--light);
         }

         span {
            font-size: 16px;
            font-weight: 400;
            line-height: 100%;
            color: var(--light);
            white-space: nowrap;
         }

         &:not(:last-child) {
            padding-right: 16px;
            
            &:after {
               content: '';
               position: absolute;
               top: 0;
               right: 0;
               width: 1px;
               height: 100%;
               background: #898989;
            }
         }

         &:hover {
            i, span {
               color: var(--action_secondary);
            }
         }
      }

      .social-item {
         position: relative;
         transition: all .2s ease;
         
         i {
            margin-top: 2px;
            color: var(--light);
            font-size: 16px;
            display: inline-block;
            transition: all .2s ease;
         }

         &.social-tooltip {
            position: relative;
   
            .tooltip {
               padding: 8px 20px;
               position: absolute;
               bottom: -80px;
               right: 0;
               background: rgba(18, 22, 23, .8);
               width: max-content;
               opacity: 0;
               visibility: hidden;
               border-radius: 5px;
               transition: all .3s ease;
   
               p {
                  font-family: var(--text_font);
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 100%;
                  color: var(--light);
                  white-space: nowrap;
               }
            }
         }
         &:hover {
            i {
               transform: translateY(-3px);
               color: var(--action_secondary);
            }

            .tooltip {
               opacity: 1;
               visibility: visible;
               bottom: -30px;
               
               &:hover {
                  background: #0AB5C5;
               }
            }
         }
      }
   }

   @media all and (max-width: 600px) {
      .header-action .header-bottom__link {
         span {
            background: -webkit-gradient(linear, left top, left bottom, from(var(--text)));
            background-repeat: no-repeat;
            background-position: left 0% bottom;
            background-size: 100% 1px;
         }

         &:hover {
            span {
               background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
               background-repeat: no-repeat;
               background-position: left 0% bottom;
               background-size: 100% 1px;
            }
         }
      }
   }

   @media all and (max-width: 460px) {
      .header-action { display: none; }
      .logo { order: 1; }
   }
}



// burger
.burger {
   padding: 0;
   width: 30px;
   height: 20px;
   position: relative;
   display: none;
   cursor: pointer;
   align-items: center;

   &:before, &::after {
      content: '';
      position: absolute;
      width: 100%;
      background: var(--light);
   }

   &:before {
      top: 0;
      right: 0;
   }
   &::after {
      bottom: 0;
      left: 0;
   }

   span {
      display: inline-block;
      width: 100%;
      background: var(--light);
   }

   span, &:before, &:after {
      height: 2px;
      border-radius: 40px;
      transition: all 0.2s ease-out;
   }

   &.burger_sticky {
      display: none;
   }

   &.active, &:hover {
      &:before, &:after { width: 50%; }
   }
}

@media all and (max-width: 1340px) {
   header {
      .header-bottom {
         .header-menu {
            margin-right: calc((100vw - 1100px) / (1340 - 1100) * (80 - 1) + 1px);
         }
      }
   }
}

@media all and (max-width: 1100px) {
   header {
      .header-bottom {
         .header-menu {
            margin-right: 0;
         }
      }
   }
}

@media all and (max-width: 900px) {
   .burger { display: flex; }

   header {
      .header-bottom {
         .ui-container { gap: 20px; }
         .logo { max-width: 130px; }
         .header-menu { display: none; }
      }
   }
}

@media all and (max-width: 460px) {
   .burger {
      order: 2;
   }
}

@media all and (max-width: 550px) {
   .burger.burger_sticky {
      display: flex;
   }
}
