.popup-wrap {
   padding: 40px 10px;
   display: grid;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   min-height: 100%;
   overflow: auto;
   z-index: 3;
   opacity: 0;
   visibility: hidden;
   transition: all .3s ease;
   
   .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
      background: var(--overlay);
   }

   .popup {
      margin: auto;
      padding: 45px 50px;
      max-width: 600px;
      width: 100%;
      position: relative;
      z-index: 4;
      opacity: 0;
      visibility: hidden;
      transform: translateY(30px);
      transition: all .4s ease;
      border-radius: 10px;
      background: var(--modal_bg);
      box-shadow: 0px 10px 100px 0px rgba(0, 0, 0, 0.40);
   }

   &.active {
      opacity: 1;
      visibility: visible;
      .popup {
         opacity: 1;
         visibility: visible;
         transform: none;
         transition-delay: 0.4s;
      }
   }

   @media all and (max-width: 500px) {
      .popup {
         padding: 50px 20px 30px 20px;
      }
   }
}