.notification {

	.note-content {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.note-title {
		margin-bottom: 20px;
		display: flex;
		align-items: center;
		gap: 20px;

		span {
			font-family: var(--title_font);
			font-size: 18px;
			font-weight: 800;
			line-height: 110%;
			color: var(--action);
			text-transform: uppercase;
		}

		img {
			max-width: 40px;
			height: auto;
		}
	}

	.note-text {
		font-family: var(--text_font);
		font-size: 17px;
		font-weight: 400;
		line-height: 120%;
		max-width: 430px;
		color: var(--text);
		text-align: center;
	}

	.note-btn {
		margin-top: 30px;
		padding: 7px 20px;
		min-height: 40px;
		background: transparent;
		text-transform: uppercase;
		border: 2px solid var(--success);

		&:before {
			background: var(--success);
		}

		span {
			font-size: 14px;
			color: var(--success);
		}

		&:hover {
			span {
				color: var(--light);
			}
		}
	}

	@media all and (width < 450px) {
		.note-title {
			flex-direction: column;

			span { text-align: center; }

			img { max-width: 60px; }
		}
	}
}