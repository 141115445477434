footer {
   padding: 105px 0 70px 0;
   position: relative;
   background: var(--footer_bg);
   overflow: hidden;

   &:after {
      content: '';
      position: absolute;
      left: -390px;
      bottom: -60px;
      width: 706.992px;
      height: 172.492px;
      transform: rotate(-32.841deg);
      border-radius: 706.992px;
      opacity: 0.3;
      background: radial-gradient(50% 50% at 50% 50%, #5DE9E9 0%, rgba(93, 233, 233, 0.30) 52.4%, rgba(93, 233, 233, 0.11) 77.4%, rgba(93, 233, 233, 0.00) 100%);
   }

   .ui-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;

      .planet {
         position: absolute;
         right: -24cqi;
         top: -240px;
         width: 60cqi;
         height: 320%;
         object-fit: cover;
         object-position: left center;
         opacity: .1;
      }
   }

   .footer-col {
      margin-top: 7px;
      max-width: 180px;
      position: relative;
      z-index: 1;

      &.main-col {
         margin-top: 0;
         flex: 0 0 230px;
         max-width: 230px;
      }

      .logo {
         margin-bottom: 22px;
         transition: all .2s ease;

         img {
            max-width: 170px;
            height: auto;
         }

         &:hover {
            transform: translateX(5px);
         }
      }

      .logo-text {
         margin-top: 20px;
         margin-bottom: 35px;
         font-size: 16px;
         font-weight: 400;
         line-height: 150%;
         color: var(--action_secondary);
      }

      .col-title, .col-title > a {
         margin-bottom: 20px;
         font-family: var(--title_font);
         font-size: 18px;
         font-weight: 600;
         line-height: 166.667%;
         color: var(--light);

         & > a:hover {
            color: var(--action_secondary);
         }
      }

      ul {
         display: flex;
         flex-direction: column;

         li {
            &:not(:last-child) {
               margin-bottom: 12px;
            }
         }

         li a, li {
            font-family: var(--text_font);
            font-size: 14px;
            font-weight: 400;
            line-height: 130%;
            color: var(--text);
         }

         li a {
            cursor: pointer;

            &:hover {
               color: var(--action_secondary);
            }
         }
      }

      .footer-link {
         font-family: var(--text_font);
         font-size: 14px;
         font-weight: 400;
         line-height: 180%;
         color: var(--text);
         max-width: 140px;
         display: block;

         &:hover {
            color: var(--action);
         }
      }

      .text {
         font-size: 14px;
         font-weight: 400;
         line-height: 180%;
         color: var(--text);
         max-width: 140px;
      }
   }

   .social-link {
      font-family: var(--text_font);
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      color: var(--light);
      display: inline-flex ;
      align-items: center;
      gap: 10px;

      i {
         font-size: inherit;
      }

      &:hover {
         span, i {
            color: var(--action_secondary);
         }
      }
   }

   @media all and (min-width: 880px) {
      .maxw880 { display: none; }
      .minw880 { display: flex; }
   }

   @media all and (max-width: 1070px) {
      .footer-col {
         margin-top: 0;
         margin-bottom: 40px;

         &.main-col {
            max-width: 100%;
            flex: 0 0 100%;
         }

         .logo-text {
            margin-bottom: 15px;
         }
      }
   }

   @media all and (max-width: 880px) {
      .footer-col {
         max-width: 50%;
         flex: 1 1 50%;

         .col-title {
            margin-bottom: 15px;
         }
      }

      .maxw880 {
         margin-top: 20px;
         display: flex;
         width: 100%;
         position: relative;
         z-index: 1;
      }

      .minw880 {
         display: none;
      }
   }

   @media all and (max-width: 600px) {
      .footer-col {
         .social-link {
            span {
               background-image: -webkit-gradient(linear, left top, left bottom, from(var(--text)));
               background-repeat: no-repeat;
               background-position: left 0% bottom;
               background-size: 100% 1px;
            }

            &:hover {
               span {
                  background-image: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
                  background-repeat: no-repeat;
                  background-position: left 0% bottom;
                  background-size: 100% 1px;
               }
            }
         }

         // .footer-link {
         //    span, a {
         //       background: -webkit-gradient(linear, left top, left bottom, from(var(--text)));
         //       background-repeat: no-repeat;
         //       background-position: left 0% bottom;
         //       background-size: 100% 1px;
         //    }

         //    &:hover {
         //       span, a {
         //          background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
         //          background-repeat: no-repeat;
         //          background-position: left 0% bottom;
         //          background-size: 100% 1px;
         //       }
         //    }
         // }

         ul li a, 
         ul li span,
         .footer-linkб span,
         .footer-link a {
            background: -webkit-gradient(linear, left top, left bottom, from(var(--text)));
            background-repeat: no-repeat;
            background-position: left 0% bottom;
            background-size: 100% 1px;

            &:hover {
               background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
               background-repeat: no-repeat;
               background-position: left 0% bottom;
               background-size: 100% 1px;
            }
         }

         .col-title {
            margin-bottom: 15px;

            a {
               background: -webkit-gradient(linear, left top, left bottom, from(var(--light)));
               background-repeat: no-repeat;
               background-position: left 0% bottom;
               background-size: 100% 1px;
   
               &:hover {
                  background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
                  background-repeat: no-repeat;
                  background-position: left 0% bottom;
                  background-size: 100% 1px;
               }
            }
         }
      }
   }

   @media all and (max-width: 360px) {
      .footer-col {
         max-width: 100%;
         flex: 1 1 100%;
      }
   }
}

.copyright {
   padding: 16px 0;
   background: var(--modal_bg);
   mix-blend-mode: multiply;

   .ui-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 40px;
      row-gap: 15px;
      flex-wrap: wrap;
   }

   .text {
      margin-right: auto;
      color: var(--text);
      font-family: var(--text_font);
      font-size: 12px;
      font-weight: 400;
      line-height: 150%;
   }

   .social-links {
      display: flex;
      align-items: center;
      gap: 13px;

      .vi-link-parent {
         display: flex;
         align-items: center;
         gap: 5px;
         position: relative;

         span, i {
            color: var(--text);
         }

         span {
            font-family: var(--title_font);
            font-size: 12px;
            font-weight: 400;
            line-height: 100%;
            white-space: nowrap;
         }

         i {
            font-size: 12px;
         }

         &:hover {
            span, i {
               color: var(--action_secondary);
            }
         }

         &:not(:last-child) {
            padding-right: 13px;
            
            &:after {
               content: '';
               position: absolute;
               top: 0;
               right: 0;
               width: 1px;
               height: 100%;
               background: #898989;
            }
         }
      }
   }

   .secure {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      &__logo {
         padding-right: 10px;
         max-width: 80px;
         position: relative;

         img {
            max-width: 100%;
            height: auto;
         }

         &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 1px;
            height: 100%;
            background: #898989;
         }
      }

      &__text {
         font-size: 12px;
         font-weight: 400;
         line-height: 100%;
         color: var(--light);
      }
   }

   @media all and (max-width: 890px) {
      .secure {
         width: 100%;
         justify-content: center;
         
      }
   }

   @media all and (max-width: 590px) {
      .ui-container {
         flex-direction: column;
         align-items: center;
      }

      .text { 
         width: 100%; 
         text-align: center;
      }

      .secure {
         margin-top: 10px;
      }
   }

   @media all and (max-width: 340px) {
      .social-links {
         flex-direction: column;
         align-items: center;
         justify-content: center;
         gap: 7px;

         .vi-link-parent:not(:last-child) {
            padding-right: 0;

            &:after {
               display: none;
            }
         }
      }
   }
}