.ui-accordion {
  display: grid;
  grid-template: auto / 1fr;
  gap: 10px;

  &__item {
    background: var(--dark_content_bg);
    border-radius: 5px;

    .item-title {
      padding: 7px 20px;
      background: var(--dark_title_bg);
      min-height: 58px;
      cursor: pointer;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      transition: all 0.2s ease;

      .title {
        font-family: var(--text_font);
        font-size: 16px;
        font-weight: 400;
        line-height: 160%;
        color: var(--light);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 15px;
        container-type: inline-size;
        width: 100%;

        img {
          width: clamp(22px, 11cqi, 35px);
          height: clamp(22px, 11cqi, 35px);
          box-shadow: -3px 3px 4px 0 rgba(0, 0, 0, 0.1);
          display: inline-block;
          border-radius: 50%;

          &:not(:first-child) {
            margin-left: -23px;
          }
        }
      }

      .icon {
        display: inline-block;
        position: relative;
        transition: all 0.2s ease;
        font-size: 11px;
        color: var(--light);
        text-align: center;
        font-family: var(--title_font);
        font-size: 26px;
        font-weight: 300;
        line-height: 20px;
        transition: all 0.3s ease;
      }
    }

    .item-content {
      padding: 25px 20px;

      ul,
      ol {
        padding-left: 30px;

        li {
          font-family: var(--text_font);
          font-size: 14px;
          font-weight: 400;
          line-height: 180%;
          color: var(--text);
        }

        &.mb15 {
          margin-bottom: 15px;
        }
      }

      ul {
        list-style: disc;
      }

      ol {
        list-style: auto;
      }

      .text {
        font-family: var(--text_font);
        font-size: 14px;
        font-weight: 400;
        line-height: 180%;
        color: var(--text);

        &.mb15 {
          margin-bottom: 15px;
        }
      }

      a {
        color: white;
        text-decoration: underline;
        text-decoration-skip-ink: none;

        &:hover {
          color: var(--action);
        }
      }
    }

    &:hover {
      .item-title {
        background: var(--action);

        .icon {
          transition: all 0.3s ease;
          animation: spinX 1s ease forwards;
        }
      }
    }

    &.active {
      .item-title {
        background: var(--action);

        .icon {
          transform: rotate(45deg);
          transition: all 0.3s ease;
        }
      }

      &:hover {
        .item-title {
          .icon {
            animation: none;
            transform: rotate(45deg);
            transition: all 0.3s ease;
          }
        }
      }
    }

    &.selected {
      .item-title {
        background: #c60000;
      }

      &.active,
      &:hover {
        .item-title {
          background: var(--action);
        }
      }
    }

    &.hide {
      display: none;
    }
  }
}

.about-screen {
  .ui-accordion {
    &__item {
      .item-title {
        .title {
          font-family: var(--title_font);
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}
