.visauly_hidden {
   border: 0;
   height: 1px;
   margin: -1px;
   overflow: hidden;
   padding: 0;
   position: absolute;
   width: 1px;
   clip: rect(0 0 0 0);
}


.form-wrap {
   max-width: 600px;
   background: var(--modal_bg);
   border-radius: 10px;

   .form-titles {
      .form-title {
         margin: 0 auto 20px auto;
         font-size: 26px;
         font-weight: 600;
         line-height: 120%;
         color: var(--light);
         text-align: center;
         max-width: 400px;
      }

      .form-subtitle {
         margin: 0 auto 30px auto;
         font-family: var(--text_font);
         font-size: 14px;
         font-weight: 300;
         line-height: 150%;
         color: var(--text);
         text-align: center;
         max-width: 340px;

         a {
            span {
               background: -webkit-gradient(linear, left top, left bottom, from(#2EABFF));
               background-repeat: no-repeat;
               background-position: left 0% bottom;
               background-size: 100% 1px;
               color: #2EABFF;
            }
         
            i {
               font-size: 11px;
               color: #2EABFF;
            }

            &:hover {
               i {
                  color: var(--action_secondary);
               }

               span {
                  background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
                  background-repeat: no-repeat;
                  background-position: left 0% bottom;
                  background-size: 100% 1px;
                  color: var(--action_secondary);
               }
            }
         }
      }

      .form-link {
         margin: 0 auto 16px auto;
         display: block;
         text-align: center;
         
         span {
            color: var(--text);
            font-family: var(--text_font);
            font-size: 14px;
            font-weight: 300;
            line-height: 150%;
            transition: all .2s ease;
         }

         i {
            font-size: 15px;
            color: var(--text);
            transition: all .2s ease;
         }

         &:hover {
            span {
               strong {
                  color: var(--light);
                  text-decoration: underline;
                  transition: all .2s ease;
               }

               i {
                  color: var(--action);
               }
               
            }
         }
      }
   }
   
   .message-status {
      margin-top: 30px;
      padding: 7px 20px;
      display: none;
      font-family: var(--text_font);
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;

      &.success {
         background: #D2F4CF;
         border-left: 4px solid #0FB800;
         display: block;
         color: #0FB800;
      }

      &.error {
         background: #D79393;
         border-left: 4px solid #A40000;
         display: block;
         color: #A40000;
      }
   }
}

.form {
   display: grid;
   grid-template: auto / 1fr;
   gap: 12px;

   .form-fields {
      display: grid;
      grid-template: auto / 1fr;
      gap: 12px;
   }

   .form-item {
      width: 100%;

      .field {
         padding: 18px 20px;
         border-radius: 5px;
         background: var(--controls_bg);
         color: var(--light);
         font-family: var(--text_font);
         font-size: 14px;
         font-weight: 400;
         line-height: 100%;
         width: 100%;

         &::placeholder {
            color: #7F8889;
         }
      }

      textarea {
         min-height: 100px;
         border: none;
      }

      .error-message {
         position: relative;
         top: 2px;
         color: #A40000;
         font-family: var(--text_font);
         font-size: 12px;
         font-weight: 400;
         line-height: 100%;
         display: none;
      }

      &.error {
         .field {
            background: #D79393;
            color: #A40000;

            &::placeholder {
               color: #A40000;
            }
         }

         .error-message {
            display: inline;
         }
      }

      &.none {
         display: none;
      }
   }

   .form-submit {
      margin-top: 30px;
      display: flex;
      align-items: center;
      column-gap: 30px;
      row-gap: 15px;

      [type="submit"] {
         padding: 9px;
         font-size: 17px;
         font-weight: 500;
         line-height: 100%;
         min-height: 50px;
         max-width: 170px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
      }

      .text {
         font-family: var(--text_font);
         font-size: 12px;
         font-weight: 400;
         line-height: 120%;
         color: var(--slowlest_text);
         max-width: 280px;

         a {
            text-decoration: underline;
            color: var(--slowlest_text);

            &:hover {
               color: var(--action);
            }
         }
      }
   }

   @media all and (max-width: 450px) {
      .form-submit {
         flex-direction: column;
         align-items: flex-start;

         .text {
            max-width: 275px;
         }
      }
   }
}



.feedback-methods {
   margin-top: 30px;

   fieldset {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
   }

   .label-title {
      margin-bottom: 15px;
      font-family: var(--text_font);
      font-size: 17px;
      font-weight: 400;
      line-height: 105%;
      color: var(--text);
   }
}

.ui-radio {
   position: relative;
   cursor: pointer;

   &__custom {
      padding: 7px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 35px;
      font-family: var(--text_font);
      font-size: 15px;
      font-weight: 400;
      line-height: 100%;
      color: var(--text);
      background: var(--controls_bg);
      border-radius: 5px;
      white-space: nowrap;
      transition: all .2s ease;
   }

   &__input:checked + &__custom {
      background: var(--action);
      color: var(--light);
   }
}


