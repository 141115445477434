%viLink {
   padding: 1px 0;
   background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
   background-size: 0% 1px;
   background-position: right 0% bottom;
   background-repeat: no-repeat;
   cursor: pointer;
   transition: 
      background-size .2s ease,
      color .2s ease,
      transform .2s ease,
}

%viLinkHover {
   color: #5DE9E9;
   background-position: left 0% bottom;
   background-size: 100% 1px;
}

.vi-link {
   @extend %viLink;

   &:hover {
      @extend %viLinkHover;
   }
}

.vi-link-parent {
   .vi-link-child {
      @extend %viLink;
   }

   i {
      transition: all .2s ease;
   }

   &:hover {
      .vi-link-child  {
         @extend %viLinkHover;
         // transform: translateX(2px);
      }
   }
}



