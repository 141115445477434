// @import 'dropdown-currency/dropdown-currency';
// @import 'calculator/calculator';

.profit-screen {
   margin-top: -2px;
   padding: 115px 0;
   background: url('../assets/img/profit_img.webp') no-repeat center / cover;

   .btn-group {
      margin-top: 77px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 30px;
   }

   @media all and (max-width: 610px) {
      .btn-group {
         gap: 15px;
      }
   }
}