@import 'review-slide';

.reviews-screen {
   padding: 115px 0;
   background: var(--dark);
   position: relative;
   overflow: hidden;

   &:after {
      content: '';
      position: absolute;
      bottom: 20px;
      left: -340px;
      width: 706.992px;
      height: 172.492px;
      transform: rotate(-32.841deg);
      border-radius: 706.992px;
      opacity: 0.3;
      background: radial-gradient(50% 50% at 50% 50%, #5DE9E9 0%, rgba(93, 233, 233, 0.30) 52.4%, rgba(93, 233, 233, 0.11) 77.4%, rgba(93, 233, 233, 0.00) 100%);
   }

   .swiper-container {
      position: relative;

      .swiper {
         padding: 5px 0;
         overflow: visible;
      }
      
      .swiper, .swiper-wrapper, .swiper-slide {
         width: 100%;
      }
   }
}