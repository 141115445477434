.mobile-menu-wrap {
   position: fixed;
   top: 0;
   left: 0;
   max-width: 400px;
   width: 100%;
   height: 100%;
   background: var(--modal_bg);
   z-index: 3;
   transform: translateX(-400px);
   transition: all .4s ease;

   &.active {
      transform: none;
   }

   .mobile-menu-head {
      padding: 15px 25px;
      background: #000;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .logo {
         max-width: 130px;

         img {
            max-width: 100%;
            height: auto;
         }
      }
   }

   .mobile-menu {
      padding: 30px 40px;

      .mobile-nav {
         margin-bottom: 40px;
         display: flex;
         flex-direction: column;
         align-items: center;

         li {
            width: 100%;

            a {
               padding: 14px 0;
               font-size: 19.003px;
               font-weight: 500;
               line-height: 130%;
               color: var(--light);
               text-align: center;
               display: inline-block;
               width: 100%;
               position: relative;
               transition: all .3s ease;

               &:after {
                  content: '';
                  position: absolute;
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  height: 1px;
                  width: 50px;
                  background: #3c3e3f;
                  transition: all .3s ease;
               }
            }

            &:hover {
               a {
                  color: var(--action_secondary);

                  &:after {
                     width: 60%;
                     background: var(--action_secondary);
                  }
               }
            }
         }
      }
   }

   .btn-group {
      margin-bottom: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 14px;

      .ui-btn {
         display: flex;
         max-width: 100%;
         width: 100%;
      }
   }

   .mobile-social {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      color: var(--light);
      font-size: 12px;
      font-weight: 400;
      line-height: 100%;

      i {
         font-size: 10px;
      }

      &:hover {
         i, span {
            color: var(--action_secondary);
         }
      }

      &.profile {
         margin-top: 10px;
         font-size: 14px;
         gap: 12px;

         i {
            font-size: 20px;
         }
      }
   }
}