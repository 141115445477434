@import 'vars';
@import 'mixin';
@import 'functions';

// VI
@import '../../components/vi/vi-link/vi-link';

// TODO UI
@import '../../components/ui/preloader/preloader';
@import '../../components/header/header-sticky/header-sticky';
@import '../../components/ui/button/ui-btn';
@import '../../components/ui/titles/titles';
@import '../../components/ui/ui-accordion/ui-accordion';
@import '../../components/swimming-chat/swimming-chat';
@import '../../components/mobile-menu/mobile-menu';


@import '../../components/header/header';
@import '../../components/screen/hero-screen/hero-screen';
@import '../../components/screen/contact-screen/contact-screen';
@import '../../components/footer/footer';
@import '../../components/popups/popup';
@import '../../components/notifications/notifications';
@import '../../components/popups/popup-form/form/form';



// TODO Custom styles

// Custom scrollbar for Firefox 
* {
   -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      -webkit-tap-highlight-color: transparent;
   scroll-behavior: smooth;
   scrollbar-color: var(--action), var(--ui_dark) ; /* «цвет ползунка» «цвет полосы скроллбара» */
   scrollbar-width: thin ;/* толщина */
   box-sizing: border-box;
}

// Custom scrollbar for webKit
::-webkit-scrollbar {
   background: var(--ui_dark);
   width: 5px;
   height: 5px;
}

::-webkit-scrollbar-thumb {
   background: var(--action);
   border-radius: 50px;
}

::-webkit-scrollbar-corner {
   background: transparent;
}

html,body {
   scrollbar-gutter: stable;
   position: relative;
   // all: unset; // сбрасывыает все стили до значений по умолчанию
}

html {
   font-size: 16px;
}

body {
   font-family: var(--title_font);
   position: relative;
   &.no-scroll {
      overflow: hidden;
   }
}
   
html, body, main {
   width: 100%;
   // height: 100%;
}

main {
   min-height: 100%;
}

i {
   display: inline-block;
   font-family: var(--icon_font);
}

input, input:focus, input:active {
   border: none;
}

button {
   padding: 0;
   user-select: none;
}

a {
   text-decoration-skip-ink: none;
}

.ui-overlay {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: rgba(41, 48, 51, 0.90);
   // backdrop-filter: blur(6px);
   z-index: 2;
   opacity: 0;
   visibility: hidden;
   transition: all .3s ease;

   &.active {
      opacity: 1;
      visibility: visible;
   }
}

.overlay-chat {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: transparent;
   z-index: 1;
   opacity: 0;
   visibility: hidden;
   transition: all .3s ease;

   &.active {
      opacity: 1;
      visibility: visible;
   }
}


// TODO ui-container
.ui-container {
   margin: 0 auto;
   padding: 0 20px;
   max-width: 1340px;
}


// TODO Swiper pagination
.swiper-pagination {
   margin-top: 45px;
   position: relative;
   width: 100%;
   display: flex;
   justify-content: center;
   z-index: 0;

   .swiper-pagination-bullet {
      opacity: 1;
      background: var(--light);
   }

   .swiper-pagination-bullet-active.swiper-pagination-bullet-active-main {
      background: var(--action);
   }
}



// TODO Close
.ui-close {
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;

   i {
      font-size: 20px;
      color: var(--slowlest_text);
      transition: all .2s ease;
   }

   &.absolute {
      position: absolute;
      top: 15px;
      right: 15px;
   }

   &:hover {
      i {
         color: var(--light);
      }
   } 
}


// NOTE стрелка вниз
.scroll-down {
   margin: 87px auto 74px auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
   z-index: 1;

   i {
      font-size: 11px;
      color: var(--text);
      transition: all .2s ease;
      animation: arrowMove 1.2s infinite;

      &:nth-of-type(1) {
         animation-delay: 0s;
      }
      &:nth-of-type(2) {
         animation-delay: .15s;
      }
   }

   &:hover {
      i {
         color: var(--action);
      }
   }
}

.features-screen {
   .scroll-down {
      margin: 30px auto 0 auto;
   }
}




@keyframes rotationPolygon {
   0% { transform: rotateY(0deg); }
   25% { transform: rotateY(180deg); }
   50% { transform: rotateY(360deg); }
   75% { transform: rotateY(180deg); }
   100% { transform: rotateY(0deg); }
}

@keyframes spin {
   0% { transform: none; }
   100% { transform: rotateY(360deg); }
}

@keyframes spinX {
   0% { transform: none; }
   100% { transform: rotate(180deg); }
}

@keyframes arrowMove {
   0% { opacity: 0; }
   50% { opacity: 1; }
   100% { opacity: 0; }
 }


// Скрываем значок google reCAPTCHA 
.grecaptcha-badge {
   visibility: hidden;
   opacity: 0;
}