.popup-review {
   .author {
      margin-bottom: 40px;

      &__name {
         margin-bottom: 10px;
         color: var(--light);
         font-size: 18px;
         font-weight: 700;
         line-height: 120%;
      }

      time {
         color: var(--slowlest_text);
         font-size: 13px;
         font-weight: 500;
         line-height: 100%;
      }
   }

   .text {
      margin-bottom: 40px;
      color: var(--text);
      font-family: var(--text_font);
      font-size: 14px;
      font-weight: 400;
      line-height: 160%;
   }

   .info {
      display: flex;
      align-items: center;
      gap: 12px;

      .rate {
         max-width: 75px;

         img {
            max-width: 100%;
            height: auto;
         }
      }

      .num {
         color: var(--light);
         font-family: var(--text_font);
         font-size: 13px;
         font-weight: 400;
         line-height: 100%;
      }
   }
}