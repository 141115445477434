.hero-screen {
   background: var(--modal_bg);
   position: relative;
   overflow: hidden;

   &:before {
      content: '';
      position: absolute;
      top: 36px;
      left: 0;
      width: 100%;
      height: 20cqi;
      background: linear-gradient(180deg, #3F484B 0%, rgba(63, 72, 75, 0.65) 15.42%, rgba(63, 72, 75, 0.25) 46.67%, rgba(63, 72, 75, 0.00) 96.15%);
      z-index: 1;
   }

   &:after {
      content: '';
      position: absolute;
      top: -550px;
      left: -550px;
      width: 980px;
      height: 980px;
      border-radius: 50%;
      opacity: 0.3;
      background: radial-gradient(50% 50% at 50% 50%, #5DE9E9 0%, rgba(93, 233, 233, 0.09) 82.08%, rgba(93, 233, 233, 0.00) 100%);
   }

   &.hero-screen_soft {
      background: #0A0D0D;

      &.exchange {
         .hero-screen__footer {
            margin-top: 45px;
         }
      }
   }

   @media all and (max-width: 835px) {
      &:before {  display: none;}
   }


   &__head {
      margin-bottom: clamp(50px,20cqi,83px);

      &.hero_empty {
         margin-bottom: clamp(50px,20cqi,70px);
      }

      &__titles {
         margin-bottom: clamp(20px, 10cqi, 30px);
         display: flex;
         align-items: center;
         gap: 1.5rem;
      }

      .title {
         font-size: 7.72888rem;
         font-weight: 800;
         line-height: 98%;
         letter-spacing: -0.23188rem;
         text-transform: uppercase;
         color: var(--light);

         strong {
            background: var(--titleG);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
         }
      }

      .text {
         font-size: 1.1875rem;
         font-weight: 500;
         line-height: 161%;
         color: var(--text);
         letter-spacing: 0.05938rem;
         max-width: 115px;

         &.hero_soft {
            margin-left: 15px;
            font-size: 15px;
            font-weight: 400;
            line-height: 140%;
            display: flex;
            flex-direction: column;
            max-width: 290px;

            img {
               margin-top: 7px;
               display: inline-block;
               max-width: 130px;
            }
         }
      }

      &.hero_empty {
         .text {
            max-width: 150px;
         }
      }
   }

   .price-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 160%;
      color: var(--light);

      a {
         color: #4ECCDD;
         font-weight: 700;
         background: -webkit-gradient(linear, left top, left bottom, from(var(--action_secondary)));
         background-repeat: no-repeat;
         background-position: left 0% bottom;
         background-size: 100% 1px;
         transition: all .2s ease;

         &:hover {
            color: var(--light);
            background: -webkit-gradient(linear, left top, left bottom, from(var(--light)));
            background-repeat: no-repeat;
            background-position: left 0% bottom;
            background-size: 100% 1px;
         }
      }

      @media all and (max-width: 570px) {
         margin-bottom: 13px;
         line-height: 110%;
      }
   }

   &__body {
      &__titles {
         .steps-title {
            margin-bottom: 41px;
            font-size: clamp(20px, 3cqi, 22px);
            color: var(--light);
            font-weight: 700;
            line-height: 149%;
            letter-spacing: 0.22px;
            text-transform: uppercase;
   
            &.sm {
               margin-bottom: 5px;
               color: var(--light);
               font-size: clamp(18px, 3cqi, 22px);
               font-weight: 400;
               line-height: 149%;
               letter-spacing: 0.22px;
               text-transform: none;
            }

            &.hero_empty {
               max-width: 560px;
            }

            &.service {
               font-size: clamp(20px, 3cqi, 24px);
               max-width: 600px;
               text-transform: none;
            }
         }
      }

      .hero-steps {
         display: grid;
         grid-template: auto / repeat(auto-fit, minmax(210px, 1fr));
         gap: 30px;
         max-width: 900px;

         &__item {
            padding: 34px 2cqi 30px 60px;
            border-radius: 0.625rem;
            border: 1px solid #000;
            background: var(--glassG);            
            backdrop-filter: blur(5px);
            position: relative;
            border-image-slice: 1;
            border-image-source: radial-gradient(at center, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0));
            min-height: 70px;
            transition: all .3s ease;

            .step-text {
               color: var(--text);
               font-family: Roboto,sans-serif;
               font-size: 16px;
               font-weight: 400;
               line-height: 150%;
               max-width: 400px;

               strong {
                  color: var(--light);
               }

               a {
                  color: #3ED8D8;
                  text-decoration: underline;
                  text-decoration-skip-ink: none;
                  white-space: nowrap;
                  transition: all .1s ease;

                  &:hover {
                     color: var(--light);
                  }

                  &.color {
                     color: #FF36A6;

                     &:hover {
                        color: var(--light);
                     }
                  }
               }
            }

            .step-title {
               margin-bottom: 30px;
               font-size: 19px;
               font-weight: 700;
               line-height: 150%;
               color: var(--light);
               text-transform: uppercase;
               max-width: 205px;
            }

            .consult_link {
               display: flex;
               align-items: center;
               gap: 8px;
               font-size: 15px;
               font-weight: 500;
               color: var(--light);
               white-space: nowrap;
               cursor: pointer;
         
               span {
                  text-decoration: underline;
                  transition: all .15s ease;
               }
         
               i {
                  margin-bottom: -4px;
                  font-size: 9px;
                  transition: all .15s ease;
               }
         
               &:hover {
                  span { text-decoration: underline; color: var(--action); }
                  i { transform: translateX(3px); color: var(--action); }
               }
            }

            .step-index {
               position: absolute;
               top: -26px;
               left: -28px;
               width: 74px;
               height: 74px;
               display: inline-block;
               transition: all .8s ease;
            }

            &:nth-child(2) {
               .step-text {
                  max-width: 167px;
               }
            }

            &:hover {
               transform: translateY(-5px);

               .step-index {
                  animation: spin 1.2s ease forwards;
               }
            }
         }
      }
   }

   &__footer {
      margin-top: clamp(50px, 13cqi, 78px);
      display: inline-flex;
      align-items: center;
      flex-wrap: wrap;
      gap: clamp(15px, 4cqi, 30px);

      &.hero_empty {
         margin-top: clamp(70px, 15cqi, 130px);
      }
   }

   .hero-img {
      position: absolute;
      width: 47cqi;
      height: 100%;
      top: 0;
      right: 0;
      z-index: 0;
      object-fit: cover;
      object-position: left center;

      &.img_soft {
         width: 100%;
         object-position: 50% center;
      }
   }

   .hero-content {
      padding-bottom: clamp(60px, 13cqi, 104px);
      padding-top: clamp(120px, 23cqi, 175px);
      position: relative;
      z-index: 1;
      container-type: inline-size;

      &.hero_service {
         .hero-steps {

            &__item {
               padding-left: 50px;
            }

            @media all and (width < 900px) {
               grid-template: auto / 1fr 1fr;
            }

            @media all and (width < 630px) {
               grid-template: auto / 1fr;
            }
         }
      }

      @media all and (max-width: 990px) {
         .hero-screen__head {
            .hero-screen__head__titles {
               flex-direction: column;
               align-items: flex-start;
               
               .text {
                  margin-left: 0;
                  font-size: clamp(17px, 5cqi, 20px);
                  max-width: 400px;
               }
            }
         }
      }

      @container (max-width: 670px) {
         &.hero_soft {
            .hero-screen__head {
               .hero-screen__head__titles {
                  .text {
                     font-size: 2.7cqi;
                     max-width: 400px;
                  }
               }
            }
         }
         .hero-screen__head {
            .hero-screen__head__titles {
               .title {
                  font-size: 18cqi;
               }
            }
         }
      }

      @container (max-width: 470px) {
         &.hero_soft {
            .hero-screen__head {
               .hero-screen__head__titles {
                  .text {
                     font-size: clamp(15px, 5cqi, 17px);
                  }
               }
            }
         }

         .hero-screen__head {
            .hero-screen__head__titles {
               .title {
                  font-size: clamp(53px, 22cqi, 22cqi);
               }
               .text {
                  font-size: clamp(17px, 5cqi, 5cqi);
                  max-width: max-content;
               }
            }
         }

         .hero-screen__footer {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
         }
      }
   }

   &__list {
      li {
         padding-left: 40px;
         font-size: clamp(21px, 4cqi, 32px);
         font-weight: 700;
         line-height: 120%;
         color: var(--light);
         text-transform: uppercase;
         position: relative;

         &:not(:last-child) {
            margin-bottom: 15px;
         }
         
         &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            width: 23px;
            height: 22px;
            background: url('../assets/img/icons/hero_list.svg') no-repeat center / contain;
         }
      }

      @media all and (max-width: 500px) {
         br {
            display: none;
         }
      }
   }

   .hero-text {
      margin-top: 100px;
      font-size: 20px;
      font-weight: 400;
      line-height: 147%;
      color: var(--light);
      max-width: 570px;

      strong {
         font-weight: 700;
      }
   }

   &.service {
      @media all and (width < 900px) {
         .hero-img.img_soft {
            object-position: 60% center;
         }
      }
   }
}
